const buttonsData = [
    ['AC','allclear'],
    ['C','function'],
    ['±','function'],
    ['%','function'],
    ['7','number'],
    ['8','number'],
    ['9','number'],
    ['÷','operator'],
    ['4','number'],
    ['5','number'],
    ['6','number'],
    ['×','operator'],
    ['1','number'],
    ['2','number'],
    ['3','number'],
    ['+','operator'],
    ['0','number'],
    ['.','number'],
    ['=','operator'],
    ['-','operator']
]

export default buttonsData