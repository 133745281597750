import React from 'react'
import Led from './Led'
import './Display.css'

const Display = ({ display }) => {  
  function convertedNumber() {
    if (display.includes('.')) {
      console.log('decimal')
    }
    if ((display > 9999999999) || (display < -999999999)) {
      display = 'E' //check number is between -999999999 and +99999999999
    } else if (display.includes('.')) {
      display = display.toString().slice(0,11) //if decimal truncate to 10 digits + decimal
    } else {
      display = display.toString()
    }
    return (display)
  }

  return (
    <span className='DisplaySpan'>
      <Led display={convertedNumber()} />
    </span>
  )
}

export default Display