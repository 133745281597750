import React, { useState } from 'react'
import Button from '../Button/button'
import Display from '../Display/Display'
import buttonsData from '../Button/buttonsData'
import './App.css'

function App() {
  const [value, setValue] = useState('0')
  const [memory, setMemory] = useState(null)
  const [operator, setOperator] = useState(null)

  const calculate = () => {
    if (operator !== null) {
      if (operator === '+') {
        setMemory(memory + parseFloat(value))
      } else if (operator === '-') {
        setMemory(memory - parseFloat(value))
      } else if (operator === '×') {
        setMemory(memory * parseFloat(value))
      } else if (operator === '÷') {
        setMemory(memory / parseFloat(value))
      }
    } else {
      setMemory(parseFloat(value))
  }
}

  const handleButtonPress = content => () => {
    const num = parseFloat(value)

    

    if (content === 'AC') {
      setValue('0')
      setMemory(null)
      setOperator(null)
      return
    }

    if (content === 'C') {
      setValue('0')
      return
    }

    if (content === '±') {
      setValue((num * -1).toString())
      return
    }

    if (content === '%') {
      setValue((num / 100).toString())
      setMemory(null)
      setOperator(null)
      return
    }

    if (content === '.') {
      if (value.includes('.')) return
      setValue(value + '.')
      return
    }

    if (content === '+') {
      calculate()
      setValue('0')
      setOperator('+')
      return
    }
    if (content === '-') {
      calculate()
      setValue('0')
      setOperator('-')
      return
    }
    if (content === '×') {
      calculate()
      setValue('0')
      setOperator('×')
      return
    }
    if (content === '÷') {
      calculate()
      setValue('0')
      setOperator('÷')
      return
    }

    if (content === '=') {
      if (!operator) return
      if (operator === '+') {
        setValue((memory + parseFloat(value)).toString())
      } else if (operator === '-') {
        setValue((memory - parseFloat(value)).toString())
      } else if (operator === '×') {
        setValue((memory * parseFloat(value)).toString())
      } else if (operator === '÷') {
        setValue((memory / parseFloat(value)).toString())
      }
      setMemory(null)
      setOperator(null)
      return
    }

    if (value[value.length - 1] === '.') {
      setValue(value + content)
    } else {
      setValue(parseFloat(num + content).toString())
    }
  }
  
  const buttonsItems = buttonsData.map((data, index) =>
    <Button 
      key={index}
      onButtonClick={handleButtonPress}
      content={data[0]}
      type={data[1]}
    />
  )

  return (
    <div className='App'>
      <div className='Display'>
        <Display display={value}/>
      </div>      
      <div className='Buttons'>
        {buttonsItems}
      </div>
    </div>
  )
}

export default App