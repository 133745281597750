import React from 'react'
import lightsData from './lightsData'
import Lights from './Lights'
import './Display.css'

const AppLED = ({ display }) => {  
  function convertToLed(){
    const reversed = [].slice.call(display).reverse()
    var decimal = 1
    var decimalcheck = 0   

    for (let index=0; index <=9; index++ ) {
      if (reversed[index] === 'E') {
        ledArray[index] = 12
      } else if 
      (reversed[index + decimalcheck] === '-') {
        ledArray[index] = 11
      } else if 
      (reversed[index + decimalcheck] == null) {
        ledArray[index] = 10
      } else { 
        if (reversed[index] === '.') {
            decimal = index + 1
            decimalcheck=1}
        ledArray[index] = parseInt(reversed[index + decimalcheck])
      }
    }
    return (decimal)
  }

  const ledOn = '#33cc33'
  const ledOff = '#333333'
  const ledArray = [10,10,10,10,10,10,10,10,10,0]
  const decimal = convertToLed()
  const lightsItems = ledArray.map((data, index)  => 
    <Lights
      key={index}
      lights={lightsData[ledArray[9-index]]}
      decimal={decimal === (10- index) ? ledOn : ledOff}
    />
  )

  return (
    <div className='Led-display'>
      {lightsItems}
    </div>
  )
}

export default AppLED